<script setup>
  import {
    onMounted,
    ref,
  } from 'vue'

  import Image from '@components/Image.vue'

  import { useAjax } from '@composables/useAjax.js'
  import { useI18n } from '@shared/i18n.js'
  import { generateUrl } from '@shared/util.js'

  const {
    blogs_count: maxCount,
    blogs_path: blogsPath
  } = window.app.page

  const articles = ref(null)

  const { t } = useI18n()
  const { callGet } = useAjax()

  onMounted(() => initialize())

  const initialize = async () => {
    const url = generateUrl('blog')
    url.searchParams.append('recently', 't')
    url.searchParams.append('count', maxCount)
    const response = await callGet(url.toString())
    articles.value = response.articles
  }

  const classArticleLeft = (index) => index <= 3 ? 'left col-5 col-sm-12' : 'left col-5'
  const classArticleRight = (index) => index <= 3 ? 'right col-7 col-sm-12' : 'right col-7'
</script>

<template>
  <div v-if="articles" class="blog_menu">
    <h2 class="c-ttl">{{ t('c.japan_blog') }}</h2>
    <div class="blog_list">
      <div v-for="(article, index) in articles" class="item">
        <a :href="article.path">
          <div :class="classArticleLeft(index)">
            <div class="photo">
              <Image :image="article.thumbnail" />
            </div>
          </div>
          <div :class="classArticleRight(index)">
            <h3>{{ article.title }}</h3>
            <p class="date">{{ article.created_at }}</p>
          </div>
        </a>
      </div>
    </div>

    <div class="link_listing_page">
      <a class="btn btn04 btn04_shadow" :href="blogsPath">{{ t('c.read_more') }}</a>
    </div>
  </div>
</template>

<style>
  #home .listing_blog .item .photo img {
    max-height: 282px;
  }

  @media screen and (max-width: 576px) {
    #home .listing_blog .item .photo img {
      height: auto;
    }
  }
</style>
