import { createApp } from 'vue'
import { createI18n } from '@shared/i18n'
import locales from '../i18n/locales.json'

import App from '@pages/welcome/App.vue'

const i18n = createI18n(locales)

document.addEventListener('DOMContentLoaded', () => {
  createApp(App).use(i18n).mount('main')
})
