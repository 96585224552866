<script setup>
  import {
    computed,
    nextTick,
    onMounted,
    ref,
    toRefs,
    watch,
  } from 'vue'

  import Loading from '@components/Loading.vue'
  import Property from '@components/PropertySmall.vue'

  import { useI18n } from '@shared/i18n.js'
  import { useAjax } from '@composables/useAjax.js'
  import { useSlick } from '@composables/useSlick.js'
  import { shuffleArray } from '@shared/util.js'

  const props = defineProps({
    kind: String,
    link: String,
    title: String,
    type: String,
    wait: {
      default: false,
      type: Boolean,
    }
  })

  const { wait } = toRefs(props)

  const emits = defineEmits(['load'])

  const { vt } = useI18n('welcome.index.js')
  const { properties_count: maxCount } = window.app.page

  const properties = ref(null)

  const { callGet } = useAjax()
  const { slickResponsive } = useSlick()

  const locale = document.querySelector('html').getAttribute('lang')
  const id = computed(() => [props.kind, props.type].join('-'))

  onMounted(() => {
    if (!props.wait) {
      getProperties()
    }
  })

  watch(wait, (val) => !val && !properties.value && getProperties())

  const initializeSlick = () => nextTick(() => slickResponsive(`#${id.value} .responsive`))

  const getProperties = async () => {
    const url = new URL(location.href)
    url.pathname = `/top/properties/${props.type}/${props.kind}.json` // このURLにロケールは入れない
    const response = await callGet(url.toString())
    if (response.properties && response.properties.length > 0) {
      Promise.allSettled(response.properties.map((property) => callGet(propertyUrl(property))))
        .then((results) => {
          emits('load')
          const list = results.filter((result) => result.status == 'fulfilled').map((result) => result.value.property)
          properties.value = shuffleArray(list).slice(0, maxCount)
          initializeSlick()
        })
    }
    else {
      properties.value = []
      initializeSlick()
    }
  }

  const propertyUrl = (property) => `${locale == 'en' ? '' : `/${locale}`}${property.path}`
</script>

<template>
  <div :id="id" class="listing">
    <h2 class="c-ttl">{{ title }}</h2>
    <div v-if="properties" class="slider responsive">
      <template v-for="(property, index) in properties" :key="`${id}-${index}`">
        <Property :property="property" />
      </template>

      <div class="item item-view-more">
        <a :href="link">
          <p>{{ vt('view_more_properties') }}</p>
        </a>
      </div>
    </div>
    <Loading v-else />
  </div>
</template>
