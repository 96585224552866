<script setup>
  import {
    onMounted,
    ref,
  } from 'vue'

  import Image from '@components/Image.vue'
  import Loading from '@components/Loading.vue'

  import { useI18n } from '@shared/i18n.js'
  import { useAjax } from '@composables/useAjax.js'
  import { shuffleArray } from '@shared/util.js'

  const emits = defineEmits(['load'])

  const {
    agencies_path: agenciesPath,
    agents_count: maxCount
  } = window.app.page

  const agents = ref(null)

  const { vt } = useI18n('welcome.index.js')
  const { callGet } = useAjax()

  onMounted(() => initialize())

  const initialize = async() => {
    const url = new URL(location.href)
    url.pathname = `${url.pathname}/top/agencies.json`
    const response = await callGet(url.toString())

    if (response.agents && response.agents.length > 0) {
      const list = []
      response.agents.forEach((agent) => {
        list.push(agent.path)
        agent.members.forEach((member) => list.push(member))
      })

      Promise.allSettled(shuffleArray(list).slice(0, maxCount).map((agent) => callGet(agent)))
        .then((results) => {
          emits('load')
          agents.value = results.filter((result) => result.status == 'fulfilled').map((result) => result.value.agent)
        })
    }
    else {
      agents.value = []
    }
  }
</script>

<template>
  <!-- Featured Agencies and Sellers -->
  <section id="sellers" class="sellers">
    <div class="section_wrap_sm">
      <h2 class="c-ttl">{{ vt('featured_agencies_and_sellers') }}</h2>
      <template v-if="agents">
        <div class="wrap">
          <div class="sellers_list">
            <div v-for="agent in agents" class="item">
              <a :href="agent.path">
                <div class="photo portrait">
                  <Image :image="agent.thumbnail" />
                </div>
                <div class="info">
                  <h3>{{ agent.name }}</h3>
                  <p class="count">{{ agent.properties }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="link_listing_page">
          <a class="btn btn04 btn04_shadow" :href="agenciesPath">{{ vt('find_more_agents') }}</a>
        </div>
      </template>
      <template v-else>
        <Loading />
      </template>
    </div>
  </section>
</template>
