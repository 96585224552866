<script setup>
  import {
    onMounted,
    nextTick,
    ref,
    watch
  } from 'vue'

  import { usePropertySearch } from '@composables/usePropertySearch.js'
  import { useI18n } from '@shared/i18n.js'
  import { generateUrl } from '@shared/util.js'

  const SELECTOR_SELECT_TAG = ".main_search .sumo_select select"

  const {
    location,
    priceTo,
    bedrooms,
    propertyType,
    type,
    optionsBedrooms,
    optionsPrefectures,
    optionsPrice,
    optionsPropertyTypes,
    propertiesUrl,
  } = usePropertySearch({
    afterGetPrefectures: () => initializeSelect()
  })

  bedrooms.value = []

  const { mt, t } = useI18n()

  onMounted(() => {
    $(SELECTOR_SELECT_TAG).SumoSelect()
  })

  const handleInputType = () => {
    initializeSelect()
    priceTo.value = ''
    bedrooms.value = []
    propertyType.value = []
  }

  const initializeSelect = () => {
    setTimeout(() => {
      $(SELECTOR_SELECT_TAG).each(function() {
        this.sumo.unload()
      })
      $(SELECTOR_SELECT_TAG).SumoSelect()
    }, 100)
  }

  const submit = () => {
    window.location.href = propertiesUrl()
  }

</script>

<template>
  <div class="main_search c-sumoselect">
    <ul class="serch_select form-search-properties">
      <li>
        <input v-model="type" class="radio-type" name="type" type="radio" value="sale" @input="handleInputType">
        <label>{{ t('c.buy') }}</label>
      </li>
      <li>
        <input v-model="type" class="radio-type" name="type" type="radio" value="rent" @input="handleInputType">
        <label>{{ t('c.rent') }}</label>
      </li>
    </ul>

    <div class="bg_selects">
      <div class="select_box">
        <div class="selects sumo_select main_select">
          <label class="select_type_a">
            <select v-model="location" class="search-location for-sale" :placeholder="mt('property', 'property_location')" tabindex="-1">
              <option disabled></option>
              <option v-for="option in optionsPrefectures" :key="`prefecture-${option.value}`" :value="option.en">{{ option.label }}</option>
            </select>
          </label>

          <label class="select_type_a">
            <select v-model="priceTo" class="search-price-to">
              <option value="">{{ t('c.max_price') }}</option>
              <option value="">{{ t('c.no_max_price') }}</option>
              <option v-for="(option, index) in optionsPrice" :key="`price-to-${index}`" :value="option">{{ option.toLocaleString() }}</option>
            </select>
          </label>
          <label class="select_type_a">
            <input name="bedrooms" type="hidden" autocomplete="off">
            <select v-model="bedrooms" name="bedrooms" multiple="multiple" :placeholder="mt('property', 'beds')">
              <option v-for="option in optionsBedrooms" :key="`bedroom-${option.value}`" :value="option.value">{{ option.text }}</option>
            </select>
          </label>

          <label class="select_type_a">
            <input name="property_type" type="hidden" autocomplete="off">
            <select v-model="propertyType" class="search-property-type for-sale" multiple="multiple" :placeholder="mt('property', 'property_type')" tabindex="-1">
              <option v-for="option in optionsPropertyTypes" :key="`property-type-${option.value}`" :value="option.value">{{ option.text }}</option>
            </select>
          </label>
        </div>
      </div>
      <div class="search">
        <button class="btn btn_search btn_search02 btn-search-main" type="button" @click="submit">
          <i class="ico-search"></i>
        </button>
      </div>
    </div>
  </div>
</template>
