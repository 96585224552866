<script setup>
  import {
    computed,
    nextTick,
    onMounted,
    ref,
  } from 'vue'

  import SideBanners from '@components/SideBanners.vue'

  import Agencies from './Agencies.vue'
  import Blog from './Blog.vue'
  import Properties from './Properties.vue'
  import SearchBox from './SearchBox.vue'

  import { useI18n } from '@shared/i18n.js'
  import { useAjax } from '@composables/useAjax.js'
  import { generateUrl, shuffleArray } from '@shared/util.js'

  const banners = ref([])
  const loaded = ref(0)

  const propertiesSalePath = window.app.page.properties_sale_path
  const propertiesRentPath = window.app.page.properties_rent_path

  const { callGet } = useAjax()
  const { vt } = useI18n('welcome.index.js')

  const LOADED_STATUS_AGENCIES = 16
  const LOADED_STATUS_PROPERTIES = 15
  const LOADED_STATUS_SALE_PROPERTIES = 3

  const loadedAgencies = computed(() => (loaded.value & LOADED_STATUS_AGENCIES) > 0)
  const loadedProperties = computed(() => (loaded.value & LOADED_STATUS_PROPERTIES) > 0)
  const loadedSaleProperties = computed(() => (loaded.value & LOADED_STATUS_PROPERTIES) > 0)

  /**
   * バナーの取得
   */
  const getBanners = async() => {
    const url = generateUrl('banners/top')
    const response = await callGet(url.toString())

    if (response.banners && response.banners.top) {
      banners.value = shuffleArray(response.banners.top)
    }
  }

  /**
   * 不動産会社の表示完了イベント
   */
  const handleLoadAgencies = () => loaded.value |= LOADED_STATUS_AGENCIES

  /**
   * 物件一覧の表示完了イベント
   */
  const handleLoadProperties = (value) => {
    loaded.value |= value

    nextTick(() => {
      if (loadedProperties.value) {
        getBanners()
      }
    })
  }
</script>

<template>
  <div class="home-mv">
    <div class="mv-img"></div>
    <div class="home_main">
      <h1 class="mv_ttl" v-html="vt('catch_copy')"></h1>
      <SearchBox />
    </div>
  </div>

  <section id="home-sale" class="home-sale">
    <div class="section_wrap">
      <Properties
        :link="propertiesSalePath"
        :title="vt('featured_properties_for_sale')"
        kind="featured"
        type="sale"
        @load="handleLoadProperties(1)"
      />
      <Properties
        :link="propertiesSalePath"
        :title="vt('newest_listings_for_sale')"
        kind="newest"
        type="sale"
        @load="handleLoadProperties(2)"
      />

      <div v-if="banners.length > 0" class="c-banner mt50" id="banner_main1">
        <a target="_blank" :href="banners[0].url">
          <img :src="banners[0].image">
        </a>
      </div>
    </div>
  </section>

  <section id="home-rent" class="home-rent">
    <div class="section_wrap">
      <Properties
        :link="propertiesRentPath"
        :title="vt('featured_properties_for_rent')"
        :wait="!loadedSaleProperties"
        kind="featured"
        type="rent"
        @load="handleLoadProperties(4)"
      />
      <Properties
        :link="propertiesRentPath"
        :title="vt('newest_listings_for_rent')"
        :wait="!loadedSaleProperties"
        kind="newest"
        type="rent"
        @load="handleLoadProperties(8)"
      />

      <div v-if="banners.length > 1" class="c-banner mt50" id="banner_main2">
        <a target="_blank" :href="banners[1].url">
          <img :src="banners[1].image">
        </a>
      </div>
    </div>
  </section>

  <Agencies v-if="loadedProperties" @load="handleLoadAgencies" />

  <section v-if="loadedAgencies" id="blod" class="blog pt-40">
    <div class="section_wrap_sm listing_blog">
      <div class="c-fBox">
        <Blog />

        <aside class="side_menu">
          <SideBanners />
        </aside>
      </div>
    </div>
  </section>

  <div id="index_about">
    <div class="wrap">
      <h2 class="c-ttl">About Japan-Property.jp</h2>
      <p v-html="vt('footer_description')"></p>
    </div>
  </div>
</template>
